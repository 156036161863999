import Vue from 'vue/dist/vue.min';
import {faq} from './components/faq';

export function vm(){
  const h = new Vue({
    el: '#site-header',
    data: {
      toggle: false
    }
  });
  const v = new Vue({
    el: '#app-faq',
    components: {
      faq: faq()
    }
  });
};