import Swiper from 'swiper/bundle';
import * as imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function price(){

};
