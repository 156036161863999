import SmoothScroll from 'smooth-scroll/dist/smooth-scroll.polyfills.min';
import Rellax from 'rellax';
import gsap from 'gsap';
import $ from 'jquery';
import ripples from 'jquery.ripples';
import * as imagesLoaded from 'imagesloaded';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function scrolEvents(){

  const header = document.getElementById('site-header')
	const nothead = document.getElementById('nofixhead')
  if(header){
    ScrollTrigger.create({
      trigger: document.querySelectorAll(".app-container")[0],
      start: "top 10%",
      end: "bottom -100%",
      toggleClass: { targets: header, className: 'active' },
    })
  }

  if(document.getElementsByClassName('rellax').length > 0){
      const rellax = new Rellax('.rellax',{
          center:true
      });
  }
  if(document.getElementsByClassName('rellax-no').length > 0){
      const rellax_no = new Rellax('.rellax-no',{
          center:false
      });
  }
    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
		header: '#site-header',
        offset: 0,
		speed: 1000,
		easing: 'easeInOutQuart',
		speedAsDuration: true,
		durationMin: 1000
    });
}