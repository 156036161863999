import Swiper from 'swiper/bundle';
import * as imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function faq(){
  const target = document.querySelectorAll('.faqbox__item');
  for(let i = 0; i<target.length; i++){
    target[i].removeEventListener('click',opener);
    target[i].addEventListener('click',opener);
  }
};

function opener(e){
  let elem = e.target;
  let child = elem.children[1];

  let list = elem.className.replace(' clickable','');
  let listElem = document.querySelectorAll('.'+list);

  for(let i = 0; i<listElem.length; i++){
    listElem[i].classList.remove('active');
    listElem[i].children[1].style.height = '0px';
  }

  if(elem.classList.contains('active') == true ){
    elem.classList.remove('active');
    child.style.height = '0px';
  }else{
    elem.classList.add('active');
    child.style.height = 'auto';
    let height = child.clientHeight + 'px';
    child.style.height = '0px';

    setTimeout(function () {
      child.style.height = height;
    }, 10);
  }
}