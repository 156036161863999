import Swiper from 'swiper/bundle';
import * as imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function index(){
  const indexSwipe = document.getElementById('indexSwipe');
  if(indexSwipe){
    new Swiper(indexSwipe, {
      slidesPerView: 'auto',
      spaceBetween: 30,
      loop: true,
      speed: 400,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".slides-pagination",
        type: "fraction",
      },
    })
  }
};

