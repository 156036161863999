import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {index} from './index';
import {faq} from './faq';
import {price} from './price';
import {vm} from './vm';
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    
    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    faq(controller , scene);
    price(controller , scene);
	
		fsLightboxInstances["group-lightbox"].props.exitFullscreenOnClose = true
});